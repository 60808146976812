import AllLoveShareInvites from "./AllLoveShareInvites";
import Instructions from "./Instructions";
import Score from "./Score";

function Home() {

    return (
        <div className="container mx-auto mb-10">
            <div className="flex min-h-screen flex-col sm:p-4 md:mx-20 lg:mx-40 xl:mx-80">
                <Score />
                <Instructions />
                <AllLoveShareInvites />
            </div>
        </div>
    )
}

export default Home
