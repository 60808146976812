import { FragmentType, graphql, useFragment } from "../gql/generated";
import LoveShareInvite from "./LoveShareInvite";

const LoveShareInvitesGroupFragment = graphql(`
fragment LoveShareInvitesGroup on LoveShareInvite {
  id
  ...LoveShareInvite
}
`);

interface Props {
    groupLoveShareInvites: FragmentType<typeof LoveShareInvitesGroupFragment>[],
    groupName: string,
    onClick: (id: string) => void;
}

function LoveShareInvitesGroup({ groupLoveShareInvites, groupName, onClick }: Props) {

    const loveShareInvites = useFragment(LoveShareInvitesGroupFragment, groupLoveShareInvites);

    return (
        <div className="p-3">
            <div className="flex flex-row px-3 text-3xl mb-2 justify-between">
                <h1>{groupName}</h1>
            </div>
            <div className="w-full rounded-xl bg-elevatedColor dark:bg-elevatedColorDark">
                {loveShareInvites.map((l) =>
                    <LoveShareInvite onClick={onClick} key={l.id} loveShareInviteFragment={l} />
                )}
            </div>
        </div>
    )
}

export default LoveShareInvitesGroup
