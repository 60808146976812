import { ApolloError, useMutation } from "@apollo/client";
import { useEffect, useState } from "react";
import { MdBoy, MdGirl } from "react-icons/md";
import { FragmentType, graphql, useFragment } from "../gql/generated";
import { LoveShareInvitesGroupFragmentDoc } from "../gql/generated/graphql";
import LoveShareInvitesGroup from "./LoveShareInvitesGroup";
import Error from "./Shared/Error";
import Modal from "./Shared/Modal";
import Placeholder from "./Shared/Placeholder";

const LoveShareInvitesFragment = graphql(`
fragment LoveShareInvites on LoveShareInvite {
  id
  points
  description
  ...LoveShareInvitesGroup
}
`);

const ADD_HISTORY = graphql(`
mutation AddHistory($input: HistoryInput!) {
  addHistory(input: $input) {
    id
    eldersQuorumPoints
    reliefSocietyPoints
  }
}
`);

interface Props {
    loading: boolean,
    error: ApolloError | undefined,
    data: FragmentType<typeof LoveShareInvitesFragment>[],
}

function LoveShareInvites({ loading, error, data }: Props) {

    const loveShareInvites = useFragment(LoveShareInvitesFragment, data);

    const [showSelected, setShowSelected] = useState(false);

    const [selectedId, setSelectedId] = useState("0");
    const [selected, setSelected] = useState(loveShareInvites.at(0));
    const [isReliefSociety, setIsReliefSociety] = useState<boolean | null>(null);

    const [addHistory] = useMutation(ADD_HISTORY);

    useEffect(() => setSelected(loveShareInvites.find(l => l.id === selectedId)), [selectedId, loveShareInvites]);

    const setAndShowSelected = (id: string) => {
        setSelectedId(id);
        setShowSelected(false);
    };

    const groups = new Map<number, [FragmentType<typeof LoveShareInvitesGroupFragmentDoc>]>();
    loveShareInvites.forEach((l) => (groups.has(l.points) ? groups.get(l.points)?.push(l) : groups.set(l.points, [l])));

    return (
        <>
            {error ?
                <Error message={error.message} />
                :
                loading ?
                    <div className="p-3">
                        <div className="flex flex-row px-3 text-3xl mb-2 justify-between">
                            <h1><Placeholder length={10} /></h1>
                        </div>
                        <div className="w-full rounded-xl bg-elevatedColor dark:bg-elevatedColorDark">
                            {Array(10).fill(0).map((_, i) =>
                                <div key={i} className="cursor-pointer border-b-2 border-lightPrimary dark:border-darkPrimaryDark last:border-b-0 bg-elevatedColor dark:bg-elevatedColorDark hover:bg-lightPrimary dark:hover:bg-darkPrimaryDark first:rounded-t-xl last:rounded-b-xl">
                                    <div className="flex flex-row p-5 w-full">
                                        <div className="text-2xl my-auto text-left">
                                            <Placeholder length={35} />
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    :
                    <>
                        {Array.from(groups.entries()).map(entry =>
                            <LoveShareInvitesGroup key={entry[0]} groupName={`${entry[0]} ${entry[0] === 1 ? "Point" : "Points"}`} groupLoveShareInvites={entry[1]} onClick={(id) => setAndShowSelected(id)} />
                        )}
                        <Modal isOpen={showSelected}>
                            <form onSubmit={(e) => { e.preventDefault(); addHistory({ variables: { input: { loveShareInviteId: Number.parseInt(selectedId), isReliefSociety: isReliefSociety ? true : false } } }); setShowSelected(false); }}>
                                <div className="p-6 flex items-start">
                                    <div className="text-center">
                                        <div className="w-full space-y-4">
                                            <div className="text-2xl text-left text-graySecondary dark:text-graySecondaryDark">{selected?.points} {selected?.points === 1 ? "point" : "points"}</div>
                                            <div className="text-2xl my-auto text-left">{selected?.description}</div>
                                            <div>
                                                <div className="text-xl mb-4">Which team would you like to record points for?</div>
                                                <div className="flex flex-row w-full justify-around mb-4 mt-1">
                                                    <div onClick={() => setIsReliefSociety(true)} className={`flex flex-row justify-end p-3 border-2 rounded-xl cursor-pointer hover:bg-lightPrimary hover:dark:bg-lightPrimaryDark hover:text-grayPrimary hover:dark:text-grayPrimaryDark ${isReliefSociety ? "bg-lightPrimary dark:bg-lightPrimaryDark text-grayPrimary dark:text-grayPrimaryDark" : "text-lightPrimary dark:text-lightPrimaryDark"}`}>
                                                        <div className="flex flex-col">
                                                            <div className="text-center">
                                                                <MdGirl className="text-6xl" />
                                                            </div>
                                                            <div className="text-5xl">RS</div>
                                                        </div>
                                                    </div>
                                                    <div onClick={() => setIsReliefSociety(false)} className={`flex flex-row justify-end p-3 border-2 rounded-xl cursor-pointer hover:bg-primary hover:dark:bg-primaryDark hover:text-grayPrimary hover:dark:text-grayPrimaryDark ${(isReliefSociety === null || isReliefSociety) ? "text-primary dark:text-primaryDark" : "bg-primary dark:bg-primaryDark text-grayPrimary dark:text-grayPrimaryDark"}`}>
                                                        <div className="flex flex-col">
                                                            <div className="text-center">
                                                                <MdBoy className="text-6xl" />
                                                            </div>
                                                            <div className="text-5xl">EQ</div>
                                                        </div>
                                                    </div>
                                                </div >
                                                {isReliefSociety != null ?
                                                    <>
                                                        <div className="text-lg text-graySecondary dark:text-graySecondaryDark">
                                                            {selected?.points} {selected?.points === 1 ? "point" : "points"} will be recorded for the {isReliefSociety ? "Relief Society" : "Elder's Quorum"}
                                                        </div>
                                                    </>
                                                    :
                                                    <div className="text-lg text-graySecondary dark:text-graySecondaryDark">
                                                        verify that the information is correct and select a team
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="px-4 py-3 sm:flex sm:flex-row sm:px-6">
                                    <button className="mb-3 sm:mb-0 inline-flex w-full justify-center rounded-lg px-4 py-2 border border-grayPrimary dark:border-grayPrimaryDark text-grayPrimary dark:text-grayPrimaryDark hover:text-graySecondary dark:hover:text-graySecondaryDark hover:bg-baseColor dark:hover:bg-baseColorDark" type="button" onClick={() => setShowSelected(false)}>Cancel</button>
                                    <input className="sm:ml-3 inline-flex w-full justify-center rounded-lg px-4 py-2 border border-transparent bg-primary dark:bg-primaryDark disabled:bg-darkPrimary disabled:dark:bg-darkPrimaryDark disabled:text-graySecondary dark:disabled:text-graySecondaryDark text-superLightPrimary dark:text-superLightPrimaryDark enabled:hover:text-lightPrimary dark:enabled:hover:text-lightPrimaryDark enabled:cursor-pointer enabled:hover:bg-superLightPrimary dark:enabled:hover:bg-darkPrimaryDark" type="submit" value="Confirm" disabled={isReliefSociety === null}></input>
                                </div>
                            </form>
                        </Modal >
                    </>
            }
        </>
    )
}

export default LoveShareInvites
