import Footer from "./Components/Footer";
import Home from "./Components/Home";

function App() {

  return <div className="bg-baseColor dark:bg-baseColorDark text-grayPrimary dark:text-grayPrimaryDark">
    <Home />
    <Footer />
  </div>
}

export default App;
