
function Instructions() {
    return (
        <div className="mt-4 mb-4 mx-4">
            <p className="text-xl text-justify">
                Welcome to our friendly love, share, and invite competition. The purpose of the competition is to promote doing as much good as you can over the next month. You will come to know how rewarding missionary work is, as you look for simple ways to love, share, and invite those around you.
            </p>
            <ul className="mt-4 mb-4 ml-4 space-y-3">
                <li className="flex flex-row space-x-3">
                    <div className="border-2 rounded-full w-8 h-8 text-center text-xl bg-lightPrimary dark:bg-lightPrimaryDark border-superLightPrimary dark:border-darkPrimaryDark">
                        <span className="m-auto">
                            1
                        </span>
                    </div>
                    <div className="text-xl my-auto">
                        Find an idea below
                    </div>
                </li>
                <li className="flex flex-row space-x-3">
                    <div className="border-2 rounded-full w-8 h-8 text-center text-xl bg-lightPrimary dark:bg-lightPrimaryDark border-superLightPrimary dark:border-darkPrimaryDark">
                        <span className="m-auto">
                            2
                        </span>
                    </div>
                    <div className="text-xl my-auto">
                        Complete the task
                    </div>
                </li>
                <li className="flex flex-row space-x-3">
                    <div className="border-2 rounded-full w-8 h-8 text-center text-xl bg-lightPrimary dark:bg-lightPrimaryDark border-superLightPrimary dark:border-darkPrimaryDark">
                        <span className="m-auto">
                            3
                        </span>
                    </div>
                    <div className="text-xl my-auto">
                        Record points for your team
                    </div>
                </li>
            </ul>
        </div>
    )
}

export default Instructions
