import { useQuery } from "@apollo/client";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { FaCrown } from "react-icons/fa/index";
import { MdBoy, MdGirl } from "react-icons/md/index";
import { graphql, useFragment } from "../gql/generated";
import Error from "./Shared/Error";
import Placeholder from "./Shared/Placeholder";
dayjs.extend(relativeTime);


const ScoreQueriesFragment = graphql(`
fragment ScoreQuery on Query {
    score {
        id
        eldersQuorumPoints
        reliefSocietyPoints
    }
}
`);

const GET_SCORE = graphql(`
    query getScore {
        ...ScoreQuery
    }
`);

function Score() {

    const { loading, error, data } = useQuery(GET_SCORE);
    const query = useFragment(ScoreQueriesFragment, data);

    return (loading || !query ?
        <>
            <div className="flex flex-row w-full justify-center text-lg mb-1 mt-4 text-center text-graySecondary dark:text-graySecondaryDark">
                <Placeholder length={35} />
            </div>

            <div className="flex flex-row w-full justify-center mb-4 mt-1">
                <div className="flex flex-row justify-end text-lightPrimary dark:text-lightPrimaryDark">
                    <div className="flex flex-col">
                        <div className="text-5xl mb-2">
                            <Placeholder length={2} />
                        </div>
                        <div className="text-5xl mt-1">
                            <Placeholder length={2} />
                        </div>
                    </div>
                    <div className="text-4xl mx-2 my-auto">
                        <Placeholder length={2} />
                    </div>
                </div>
                <div className="text-4xl mx-2 my-auto">
                    <Placeholder length={1} />
                </div>
                <div className="flex flex-row justify-start text-primary dark:text-primaryDark">
                    <div className="text-4xl mx-2 my-auto">
                        <Placeholder length={2} />
                    </div>
                    <div className="flex flex-col">
                        <div className="text-5xl mb-2">
                            <Placeholder length={2} />
                        </div>
                        <div className="text-5xl mt-1">
                            <Placeholder length={2} />
                        </div>
                    </div>
                </div>
            </div >
        </>
        :
        error ?
            <Error message={error.message} />
            :
            <>
                <div className="flex flex-row w-full justify-center text-lg mb-1 mt-4 text-center text-graySecondary dark:text-graySecondaryDark">
                    {query.score.reliefSocietyPoints === query.score.eldersQuorumPoints ?
                        <span>All tied up with {dayjs("2023-04-28").fromNow(true)} remaining</span>
                        :
                        <>
                            {query.score.reliefSocietyPoints > query.score.eldersQuorumPoints ?
                                <span>The Relief Socity won by {query.score.reliefSocietyPoints - query.score.eldersQuorumPoints} points. Join us for an activity to celebrate their victory on Wednesday, May 17th.</span>
                                :
                                <span>The Elder's Quorum leads by {query.score.eldersQuorumPoints - query.score.reliefSocietyPoints} with {dayjs("2023-04-28").fromNow(true)} remaining</span>
                            }
                        </>
                    }
                </div>

                <div className="flex flex-row w-full justify-center mb-4 mt-1">
                    <div className="flex flex-row justify-end text-lightPrimary dark:text-lightPrimaryDark">
                        <div className="flex flex-col">
                            <div className="text-center">
                                {query.score.reliefSocietyPoints > query.score.eldersQuorumPoints &&
                                    <FaCrown className="text-xs absolute ml-6 text-yellow dark:text-yellowDark" />
                                }
                                <MdGirl className="text-6xl" />
                            </div>
                            <div className="text-5xl">RS</div>
                        </div>
                        <div className="text-4xl mx-2 my-auto">{query.score.reliefSocietyPoints}</div>
                    </div>
                    <div className="text-4xl mx-2 my-auto">-</div>
                    <div className="flex flex-row justify-start text-primary dark:text-primaryDark">
                        <div className="text-4xl mx-2 my-auto">{query?.score.eldersQuorumPoints}</div>
                        <div className="flex flex-col">
                            <div className="text-center">
                                {query.score.eldersQuorumPoints > query.score.reliefSocietyPoints &&
                                    <FaCrown className="text-xs absolute ml-6 text-yellow dark:text-yellowDark" />
                                }
                                <MdBoy className="text-6xl" />
                            </div>
                            <div className="text-5xl">EQ</div>
                        </div>
                    </div>
                </div >
            </>
    )
}

export default Score
