import { FragmentType, graphql, useFragment } from "../gql/generated";

const LoveShareInviteFragment = graphql(`
fragment LoveShareInvite on LoveShareInvite {
  id
  description
  points
}
`);

interface Props {
    loveShareInviteFragment: FragmentType<typeof LoveShareInviteFragment>,
    onClick: (id: string) => void;
}

function LoveShareInvite({ loveShareInviteFragment, onClick }: Props) {

    const loveShareInvite = useFragment(LoveShareInviteFragment, loveShareInviteFragment);

    return (
        <div onClick={() => onClick(loveShareInvite.id)} className="cursor-pointer border-b-2 border-lightPrimary dark:border-darkPrimaryDark last:border-b-0 bg-elevatedColor dark:bg-elevatedColorDark hover:bg-lightPrimary dark:hover:bg-darkPrimaryDark first:rounded-t-xl last:rounded-b-xl">
            <div className="flex flex-row p-5 w-full">
                <div className="text-2xl my-auto text-left">{loveShareInvite.description}</div>
            </div>
        </div>
    )
}

export default LoveShareInvite
