/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\nfragment LoveShareInvitesQuery on Query {\n  loveShareInvites {\n    id\n    ...LoveShareInvites\n  }\n}\n": types.LoveShareInvitesQueryFragmentDoc,
    "\nquery getLoveShareInvites {\n  ...LoveShareInvitesQuery\n}\n": types.GetLoveShareInvitesDocument,
    "\nfragment LoveShareInvite on LoveShareInvite {\n  id\n  description\n  points\n}\n": types.LoveShareInviteFragmentDoc,
    "\nfragment LoveShareInvites on LoveShareInvite {\n  id\n  points\n  description\n  ...LoveShareInvitesGroup\n}\n": types.LoveShareInvitesFragmentDoc,
    "\nmutation AddHistory($input: HistoryInput!) {\n  addHistory(input: $input) {\n    id\n    eldersQuorumPoints\n    reliefSocietyPoints\n  }\n}\n": types.AddHistoryDocument,
    "\nfragment LoveShareInvitesGroup on LoveShareInvite {\n  id\n  ...LoveShareInvite\n}\n": types.LoveShareInvitesGroupFragmentDoc,
    "\nfragment ScoreQuery on Query {\n    score {\n        id\n        eldersQuorumPoints\n        reliefSocietyPoints\n    }\n}\n": types.ScoreQueryFragmentDoc,
    "\n    query getScore {\n        ...ScoreQuery\n    }\n": types.GetScoreDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\nfragment LoveShareInvitesQuery on Query {\n  loveShareInvites {\n    id\n    ...LoveShareInvites\n  }\n}\n"): (typeof documents)["\nfragment LoveShareInvitesQuery on Query {\n  loveShareInvites {\n    id\n    ...LoveShareInvites\n  }\n}\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\nquery getLoveShareInvites {\n  ...LoveShareInvitesQuery\n}\n"): (typeof documents)["\nquery getLoveShareInvites {\n  ...LoveShareInvitesQuery\n}\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\nfragment LoveShareInvite on LoveShareInvite {\n  id\n  description\n  points\n}\n"): (typeof documents)["\nfragment LoveShareInvite on LoveShareInvite {\n  id\n  description\n  points\n}\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\nfragment LoveShareInvites on LoveShareInvite {\n  id\n  points\n  description\n  ...LoveShareInvitesGroup\n}\n"): (typeof documents)["\nfragment LoveShareInvites on LoveShareInvite {\n  id\n  points\n  description\n  ...LoveShareInvitesGroup\n}\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\nmutation AddHistory($input: HistoryInput!) {\n  addHistory(input: $input) {\n    id\n    eldersQuorumPoints\n    reliefSocietyPoints\n  }\n}\n"): (typeof documents)["\nmutation AddHistory($input: HistoryInput!) {\n  addHistory(input: $input) {\n    id\n    eldersQuorumPoints\n    reliefSocietyPoints\n  }\n}\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\nfragment LoveShareInvitesGroup on LoveShareInvite {\n  id\n  ...LoveShareInvite\n}\n"): (typeof documents)["\nfragment LoveShareInvitesGroup on LoveShareInvite {\n  id\n  ...LoveShareInvite\n}\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\nfragment ScoreQuery on Query {\n    score {\n        id\n        eldersQuorumPoints\n        reliefSocietyPoints\n    }\n}\n"): (typeof documents)["\nfragment ScoreQuery on Query {\n    score {\n        id\n        eldersQuorumPoints\n        reliefSocietyPoints\n    }\n}\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query getScore {\n        ...ScoreQuery\n    }\n"): (typeof documents)["\n    query getScore {\n        ...ScoreQuery\n    }\n"];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;