import { useQuery } from "@apollo/client";
import { graphql, useFragment } from "../gql/generated";
import LoveShareInvites from "./LoveShareInvites";

const LoveShareInvitesQueriesFragment = graphql(`
fragment LoveShareInvitesQuery on Query {
  loveShareInvites {
    id
    ...LoveShareInvites
  }
}
`);

const GET_LOVESHAREINVITES = graphql(`
query getLoveShareInvites {
  ...LoveShareInvitesQuery
}
`);

function AllLoveShareInvites() {

    const { loading, error, data } = useQuery(GET_LOVESHAREINVITES);
    const loveShareInvites = useFragment(LoveShareInvitesQueriesFragment, data)?.loveShareInvites || [];

    return (
        <>
            <LoveShareInvites loading={loading} error={error} data={loveShareInvites} />
        </>
    )
}

export default AllLoveShareInvites
